import { A } from '@solidjs/router';
import type { Nodes } from 'hast';
import { Dynamic } from 'solid-js/web';

import styles from '~/components/IndividualPostLink.module.scss';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { toYearMonthPair } from '~/utils/toYearMonthPair';

interface IndividualPostLinkProps {
  title: Nodes;
  lead: Nodes;
  slug: string;
  categoryTitle: string;
  categorySlug: string;
  publishedAt: Date;
  cover: string;
  level: 'h2' | 'h3';
}

export default function IndividualPostLink(props: IndividualPostLinkProps) {
  return (
    <div class={styles.wrapper}>
      <div class={styles.image}>
        <img src={props.cover} alt="" loading="lazy" />
      </div>
      <div class={styles.meta}>
        <time dateTime={props.publishedAt.toISOString()}>
          {props.publishedAt.toLocaleString(import.meta.env.VITE_LOCALE, {
            dateStyle: 'long',
            timeStyle: 'short',
            timeZone: import.meta.env.VITE_TZ,
          })}
        </time>
        <A class={styles.category} href={`/${props.categorySlug}`}>
          {props.categoryTitle}
        </A>
      </div>
      <div class={styles.link}>
        <Dynamic component={props.level}>
          <A
            href={`/${props.categorySlug}/${toYearMonthPair(props.publishedAt)}/${props.slug}`}
          >
            {fragmentToJsx(props.title)}
          </A>
        </Dynamic>
        <p class={styles.lead}>{fragmentToJsx(props.lead)}</p>
      </div>
    </div>
  );
}
